import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { orderBy } from 'lodash';
import es6Promise from 'es6-promise';
import setupCSRFToken from '../setupCSRFToken';
import stickybits from 'stickybits';

window.addEventListener('DOMContentLoaded', setupCSRFToken);

es6Promise.polyfill();

document.addEventListener('DOMContentLoaded', () => {
  stickybits("#merchants_navbar")
});
